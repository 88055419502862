import { Router } from "@angular/router";
import { Modules } from "@ds-enums/modules.enum";

export class RedirectionMemoryModel{
    private readonly fromModule: Modules;
    public toModule: Modules;
    public url: string;
    public meta?: any
    constructor(toModule: Modules,url, meta?){
        this.fromModule = this.getModuleName();
        this.toModule = toModule;
        this.url = url;
        this.meta = meta;
    }

    getModuleName(){
        return Modules[location.href.split('//')[1].split('/')[1]];
    }
}
