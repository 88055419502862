import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@ds-env/environment";
import {  map } from "rxjs/operators";
import { Observable } from "rxjs";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";

@Injectable({
  providedIn: "root",
})
export class HttpRequestService {
  public serviceUrl: string = "";
  public apiEndPoint: string = "";
  private AWSENDPOINT: string = environment.AWSENDPOINT;
  constructor(private http: HttpClient, private commonFunctions: CommonFunctionsService) {
    this.apiEndPoint = environment.APIENDPOINT;
  }

  private createHttpOptions(api_type: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Language": "en",
        "Accept-Language": this.commonFunctions.getLang().code
      }),
    };
    return httpOptions;
  }

  public callPostApi(api_path: string, body: any) {
    let http_options = this.createHttpOptions("post");
    this.serviceUrl = this.apiEndPoint + api_path;
    return this.http.post(this.serviceUrl, body, http_options);
  }

  public callGetApi(api_path: string, queryParams?: HttpParams) {
    let http_options = this.createHttpOptions("get");
    this.serviceUrl = this.apiEndPoint + api_path;
    return this.http.get(this.serviceUrl, http_options);
  }

  public callPutApi(api_path: string, body:any) {
    let http_options = this.createHttpOptions("put");
    this.serviceUrl = this.apiEndPoint + api_path;
    return this.http.put(this.serviceUrl, body,http_options);
  }

  public callDeleteApi(api_path: string) {
    let http_options = this.createHttpOptions("delete");
    this.serviceUrl = this.apiEndPoint + api_path;
    return this.http.delete(this.serviceUrl, http_options);
  }

  public getFileAsBlob(api_path) {
    let httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/json",
        "X-Language": "en",
      }),
    };
    this.serviceUrl = this.apiEndPoint + api_path;
    return this.http
      .get(this.serviceUrl, { ...httpOptions, ...{ responseType: "blob" } })
      .pipe(
        map((res) => res)
        // catchError(this.handleError<any>('callAuthGetApi'))
      );
  }

  callUploadFileServiceApiProd(
    api_path: string,
    file: any,
    type?: string
  ): Observable<Blob> {
    let formData = new FormData();
    if (file?.input_excel) {
      formData.append("input_excel", file.input_excel);
    }
    const http_options = {
      responseType: "blob" as "json",
      headers: new HttpHeaders({
        Accept: "*/*",
        "X-Language": "en",
      }),
      reportProgress: true,
    };
    this.serviceUrl = this.apiEndPoint + api_path;
    return this.http.post<Blob>(this.serviceUrl, formData, http_options).pipe(
      map(this.extractData)
      // catchError(this.handleError<any>('callUploadFileServiceApi'))
    );
  }

  callAWSExport(api_path: string,
    type?: string){
      const path = `${this.AWSENDPOINT}${api_path}`
      return this.http.get<Blob>(path).pipe(
        map(this.extractData)
      );
  }

    public fetchBlob(apiPath: string): Observable<Blob> {
    const path = `${this.apiEndPoint}${apiPath}`
      return this.http.get(path,
        {responseType: 'blob'}
      )
    }  
  
    parseErrorBlob(err: HttpErrorResponse): Observable<any> {
      const reader: FileReader = new FileReader();
  
      const obs = Observable.create((observer: any) => {
        reader.onloadend = (e) => {
          observer.error(JSON.parse(reader.result as string));
          observer.complete();
        }
      });
      reader.readAsText(err.error);
      return obs;
  }
  
  private extractData(res: any) {
    let body = res;
    if (res.ok) {
      if (res.body.byteLength > 1000) {
        var decodedString = btoa(
          new Uint8Array(res.body).reduce(function (data, byte) {
            return data + String.fromCharCode(byte);
          }, "")
        );
        if (decodedString.includes("status_code")) {
          var obj = JSON.parse(decodedString);
          return obj;
        }
      } else {
        var decodedString: string = String.fromCharCode.apply(
          null,
          new Uint8Array(res.body)
        );
        if (decodedString.includes("status_code")) {
          var obj = JSON.parse(decodedString);
          return obj;
        }
      }
    }
    return body || {};
  }
}
