// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	APIENDPOINT: `${location.origin.includes('localhost') ?'https://dev-shelf.anchanto.com':location.origin}` + '/api/3.0/',
	AWSENDPOINT: 'https://8anm6xy9r2.execute-api.us-east-2.amazonaws.com/dev/',
	DSPRODUCTFRUITAPI: 'XmhKXXbvBH13uTm6'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
