import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, finalize, takeUntil, Subscription} from "rxjs";
import { ProductScorecardService } from "@ds-private-layouts/scorecard/product-scorecard/product-scorecard.service";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import { ProductDescriptionComponent } from "../../../product-description/product-description.component";
import { Router } from "@angular/router";

@Component({
	selector: "app-product-summary",
	templateUrl: "./product-summary.component.html",
	styleUrls: ["./product-summary.component.scss"]
})
export class ProductSummaryComponent implements OnInit {
	@Input() productId: number;
	public product;
	public isLoading: boolean = false;
	public destroy$: Subject<boolean> = new Subject();
	public clearLastCall: Subscription;

	constructor(
		public commonFunctions: CommonFunctionsService,
		private productScorecardService: ProductScorecardService,
		public ngbModal: NgbModal,
    public router: Router
	) {}

	ngOnInit(): void {
		this.getProductDetails();
	}

	getProductDetails() {
		this.isLoading = true;
		this.clearLastCall?.unsubscribe();
		this.clearLastCall = 
		this.productScorecardService
			.getProductDetails(this.productId)
			.pipe(
				finalize(() => (this.isLoading = false)),
				takeUntil(this.destroy$)
			)
			.subscribe((res: any) => {
				this.product = res?.data;
				if(this.product?.description?.trim() && !this.commonFunctions.extractTextFromHtml(this.product?.description)?.trim()){
					this.product['isExplicitDescription'] = true
				  }else{
					this.product.description =this.product?.description?.trim()? this.commonFunctions.extractTextFromHtml(this.product?.description) : null
				}
			});
	}
	closeModal() {
		this.ngbModal.dismissAll();
	}

	descriptionReadMore() {
		const modalRef = this.ngbModal.open(ProductDescriptionComponent, {
			centered: true,
			windowClass: "productDescCustomClass"
		});
		modalRef.componentInstance.descriptionData = this.product;
	}

	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}
