import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberSuffix'
})
export class NumberSuffixPipe implements PipeTransform {
  transform(input: any, args?: any, roundOff = true): any {
    let exp;
    const suffixes = ['K', 'M', 'B', 'T', 'P', 'E'];
    const isNagtiveValues = input < 0;
    if (Number.isNaN(input) || (input < 1000 && input >= 0) || !this.isNumeric(input) || (input < 0 && input > -1000)) {
      if (!!args && this.isNumeric(input) && !(input < 0) && input != 0) {
        let finalValue = this.removerUnwantedZero(input.toFixed(args));
        return finalValue;
      } else {
        return input;
      }
    }

    if (!isNagtiveValues) {
      exp = Math.floor(Math.log(input) / Math.log(1000));
      let finalValue = this.removerUnwantedZero((input / Math.pow(1000, exp)).toFixed(args))
      if(['M', 'B', 'T', 'P', 'E'].includes(suffixes[exp - 1])){
        finalValue = roundOff ? Math.round(finalValue) : finalValue
      }
      return  finalValue + suffixes[exp - 1];
    } else {
      input = input * -1;
      let finalValue = this.removerUnwantedZero((input * -1 / Math.pow(1000, exp)).toFixed(args))
      if(['M', 'B', 'T', 'P', 'E'].includes(suffixes[exp - 1])){
        finalValue = Math.round(finalValue)
      }
      return  finalValue + suffixes[exp - 1];
    }
    
  }

  removerUnwantedZero(val){
    return Number(val);
  }

  isNumeric(value): boolean {
    if (value < 0) value = value * -1;
    if (/^-{0,1}\d+$/.test(value)) {
      return true;
    } else if (/^\d+\.\d+$/.test(value)) {
      return true;
    } else {
      return false;
    }
  }
}
