import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatePipe implements PipeTransform {
  transform(date: Date | string, format?: string): string | void {
    if (date) {
      let timezone: string;
      if (typeof (date) == 'string') {
        timezone = date.split('+')[1];
      }
      date = new Date(date).toISOString();
      if (!format) {
        // format = 'MMM d, h:mm aaa';
      format = 'MMM d YYYY, h:mm aaa';
      // format = 'd MMM, YYYY h:mm:ss aaa' ;
      }
      return new DatePipe('en-US').transform(date, format, timezone);
    }
  }
}
