<div class="product-description-container">
    <div class="d-flex justify-content-between align-items-center pb-3">
        <div class="title-modal">
            <div class="text-align-right-rtl" ngbAutofocus translate="">Product Description</div>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="onBackClick()">
            <i class="icon icon-Close2"></i>
        </button>
    </div>
    <div class="product-header d-flex">
        <span class="product-info-content-1-item-1 position-relative">
            <img class="cursor-pointer" *ngIf="descriptionData?.images && descriptionData?.images?.length>0"
                src="{{descriptionData.images[0]}}" alt="">
            <img class="cursor-pointer" *ngIf="!descriptionData?.images || descriptionData?.images?.length==0"
                src="assets/images/no-image.svg" alt="">
            <span *ngIf="descriptionData?.images && descriptionData?.images?.length>1"
                class="additional-variant cursor-pointer">+{{(descriptionData?.images?.length-1)}}</span>
        </span>
        <div class="d-flex justify-content-center flex-column">
            <div class="product-info-content-3 d-flex align-items-center flex-row">
                <a [routerLink]="[commonFunctions?.redirectToScorecardInfo(descriptionData.product_id,'product',true)?.params]" [queryParams]="commonFunctions?.redirectToScorecardInfo(descriptionData.product_id,'product',true)?.queryParams" target="_blank">
                <span class="head text-align-right-rtl cursor-pointer on-hover-action" showMultiTooltipOnTextOverflow [data]="descriptionData?.name"
                    ></span>
                </a>
                <a href={{descriptionData?.url}} target="_blank" class='navigate-link' placement="bottom"
                    ngbTooltip="{{'Go to Product Channel' | translate}}" tooltipClass="info-custom-tooltip"><i
                        class="icon icon-Open-link"></i></a>
            </div>
            <div class="content-top d-flex flex-column">
                <div class="showVariants text-align-right-rtl">
                    <div ngbDropdown class="d-inline-block" placement="bottom-left">
                        <span id="dropdownBasic1" ngbDropdownToggle
                            class="cursor-pointer on-hover-action text-decoration-none"
                            *ngIf="descriptionData?.variant_count">{{'In' | translate}}
                            {{(descriptionData?.variant_count)}}
                            {{'Variants' | translate}}</span>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <div class="product-desc-area">
        <span class="d-flex desc-title" translate>Description</span>
        <div *ngIf="isLoadingData;else description">
         <span class="skeleton-box" [widthInPercentage]="100"></span>
         <span class="skeleton-box" [widthInPercentage]="100"></span>
         <span class="skeleton-box" [widthInPercentage]="100"></span>
         <span class="skeleton-box" [widthInPercentage]="100"></span>
         <span class="skeleton-box" [widthInPercentage]="90"></span>
        </div>
        <ng-template #description>
            <div class="desc-area" [innerHtml]="formatDescription(descriptionData.description) | safeHTML"></div>
        </ng-template>
    </div>
</div>