import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import { DataTransferService } from "@ds-common-services/utility-services/data-transfer.service";
import { GlobalService } from "@ds-common-services/http-services/global.service";
import { ProductDescriptionModelForPopup } from "@ds-models/scorecard.model";

export enum instrinsicScores {
	"emoji_count" = "Emojis",
	"capitalized_count" = "Capitalized",
	"misspelled_count" = "Misspelled",
	"adjective_count" = "Adjective"
}
export enum instrinsicDescription {
	"emoji_count" = "emo",
	"capitalized_count" = "cap",
	"misspelled_count" = "missp",
	"adjective_count" = "adj"
}

export type intrinsicScoreDetails = {
  emoji_count: number;
  capitalized_count: number;
  misspelled_count: number;
  adjective_count: number;
}
@Component({
	selector: "app-product-description",
	templateUrl: "./product-description.component.html",
	styleUrls: ["./product-description.component.scss"]
})
export class ProductDescriptionComponent implements OnInit {
	public objectKeys = Object.keys;
	public descriptionData: ProductDescriptionModelForPopup;
	public isDetailedView: boolean = false;
	public isLoadingData: boolean = false;
	public instrinsicScores = instrinsicScores;
	public intrinsicScoreDetails: intrinsicScoreDetails;
	public selectedInstrinsic: string = "emoji_count";
	private originalDescriptionForIntrinsicScores: string;
	private destroy$: Subject<boolean> = new Subject();
	constructor(
		public modalService: NgbModal,
		public commonFunctions: CommonFunctionsService,
		public sanitizer: DomSanitizer,
		public activeModal: NgbActiveModal
	) {}

	ngOnChanges(){
	}
	ngOnInit(): void {
		
	}

	ngAfterViewInit() {
	}


	onBackClick() {
		this.activeModal.close();
	}
	
	formatDescription(text) {
		return this.commonFunctions.replaceNewLineWithBreak(text)
	}

	ngOnDestroy(){
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}
}
